<template>
    <div class="flex w-100">
        <div class="flex w-100 center-xs middle-xs">
            <div class="flex mt-30 col middle-xs" :class="isMobileView ? 'w-90' : 'w-40'">
                <div class="flex head pl-10 pr-10 mb-30">CREATE COLLECTION</div>
                <div class="flex w-100 mt-5 mb-5">
                    <input class="w-100 pt-15 pb-15 pl-20 inputEle" type="text" placeholder="Collection Name" v-model="data.name" @focusout="data.isInvalidName = data.name == ''">
                    <div  v-if="data.isInvalidName" class="error mb-5 p-5">Collection name is required</div>
                </div>
                <div class="flex w-100 mt-5 mb-5">
                    <input class="w-100 pt-15 pb-15 pl-20 inputEle" type="text" placeholder="Description" v-model="data.info">
                </div>
                <div class="flex w-100 mt-10 mb-10">
                    <van-checkbox v-model="data.isPublic" @click="clearTests()" checked-color="#0fab61" icon-size="30px">Public Collection</van-checkbox>
                </div>
                <div class="flex w-100 mt-5 mb-5">
                    <Multiselect class="w-100 pt-15 pb-15 pl-20 inputEle" v-model="data.tests" placeholder="Select Tests" mode="tags" :close-on-select="false" :searchable="true" :options="getTests" @focusout="data.isInvalidTests = !data.tests.length" />
                    <div  v-if="data.isInvalidTests" class="error mb-5 p-5">Atleast one test is required</div>
                </div>
                <div class="flex w-100">
                    <image-uploader @image-loaded="onImageLoaded"></image-uploader>
                    <div  v-if="data.isInvalidImage" class="error mb-5 p-5">Image upload failed, Please upload again !</div>
                </div>
                <div  v-if="data.isCreationFailed" class="error mb-5 p-5">Collection creation failed, Please try agian !</div>
                <div class="flex w-100 mt-30">
                    <div class="w-40 pt-15 pb-15 buttonC" :class="isMobileView ? 'pl-10 pr-10' : 'pl-15 pr-15'" @click="navigateBack()">Back</div>
                    <div class="w-40 pt-15 pb-15 buttonC buttonR" :class="isMobileView ? 'pl-10 pr-10' : 'pl-15 pr-15'" @click="createCollection()">Create</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { isMobile } from "@/lib/utils"
import { computed, reactive, onBeforeMount } from 'vue'
import router from "@/lib/router.js"
import { post, get } from "@/lib/api.js"
import Multiselect from '@vueform/multiselect'
import ImageUploader from "../../components/ImageUploader.vue"

const data = reactive({
    isInvalidName: false,
    isInvalidTests: false,
    isInvalidImage: false,
    isCreationFailed: false,
    name: '',
    info: '',
    isPublic: false,
    tests: [],
    testsData: [],
    image: '',
    isInvalidData: function() {
        this.isInvalidTests = !this.tests.length
        this.isInvalidName = this.name == ''
        return (this.isInvalidTests || this.isInvalidName || this.isInvalidImage)
    }
})

const getTests = computed(() => {
    return data.isPublic ? data.testsData.filter((test) => { return test.isPublic }) : data.testsData
})
let imageObj;
function onImageLoaded(image){
    imageObj = image;
}

const isMobileView = computed(() => {
    return isMobile()
})
function navigateBack() {
    router.push({path: '/collections'})
}
function clearTests() {
    if(data.isPublic) {
        data.tests = data.tests.filter((test) => {
            let testData = data.testsData.filter((item) => {
                return item.value == test
            })
            return testData[0].isPublic
        })
    }
}
async function createCollection() {
    if (data.isInvalidData()) {
        return
    }
    try {
        // upload image
        const imageRes = await post('users/me/images/benchmark', true, imageObj);
        data.image = imageRes.data.imagePath

        // post collection
        const collection = await post('users/me/benchmarks', true, data);
        if(collection.status == "success") {
            router.push({path: '/collections'})
        }
    } catch (error) {
        if(['imageUploadFailed','invalidImageType'].includes(error.response.data.message)) {
            data.isInvalidImage = true
        } else {
            data.isCreationFailed = true
        }
        console.error('error adding collection:', error);
    }
}

onBeforeMount(async () => {
    try {
        let res = await get('users/me/tests', true)
        data.testsData = res.data.map((item) => {
            return {label: item.name, value: item.id, isPublic: item.isPublic}
        })
    }
    catch(error) {
        console.error("dataFetchError : " + error)
    }
})
</script>
<style lang="scss" scoped >
.buttonC {
    border-radius: 10px;
}
.button {
    border: 1px solid gray;
    border-radius: 10px;
}
.head {
    font-family: Bold;
    font-size: 20px;
    color: #000;
}
.buttonR {
    margin-left: 20%;
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
